import styles from './Video.module.scss'

const videoModal = (props) => (
  <div className={styles.wrapper}>
    <div className={styles.containerWrapper}>
      <div className={styles.loading}>
        Loading...
      </div>
      <div className={styles.videoContainer}>
        <iframe src="https://www.youtube.com/embed/aP-oFkZknS4?&autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </div>

    <div className={styles.close} onClick={props.closeVideo}>
      X CLOSE
    </div>
  </div>
)

export default videoModal