/* eslint import/no-webpack-loader-syntax: off */
import { useState, useEffect, useRef } from 'react'
import styles from '../../components/Scene/Scene.module.scss'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import mapboxgl from '!mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import PinForm from './PinForm'
import Album from './Album'
import IconAngleLeft from '../../components/Icons/AngleLeft'
import IconAngleRight from '../../components/Icons/AngleRight'


// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

let scene,camera,renderer,wrapper,modelLoader,textureLoader,raycaster,pointer;
let rendererCSS,screenMesh,cssScreen;
// let map, store;
let store;


let mapLoaded = false;
const objects = [];
const easterEggs = [];
let activeObject;
let cameraZ, cameraY;

let recordPlaying;

let flag,flyer1,flyer2,flyer3,posterBand1,posterBand2,bandPhoto1,bandPhoto2,bandPhoto3,hudiniPhoto,grammy,sock,licensePlate,vinylBanner;
let tvScreen;

const currentMarkers = [];

let loadingTimer; 

// let explosionSprite, explosionAnimator, clock;
// function TextureAnimator(texture, tilesHoriz, tilesVert, numTiles, tileDispDuration) {
//   this.tilesHorizontal = tilesHoriz;
//   this.tilesVertical = tilesVert;
  
//   this.numberOfTiles = numTiles;
//   texture.wrapS = texture.wrapT = THREE.RepeatWrapping; 
//   texture.repeat.set( 1 / this.tilesHorizontal, 1 / this.tilesVertical );

//   // how long should each image be displayed?
//   this.tileDisplayDuration = tileDispDuration;

//   // how long has the current image been displayed?
//   this.currentDisplayTime = 0;

//   // which image is currently being displayed?
//   this.currentTile = 5;
//   this.enabled = false;
//   this.update = function( milliSec )
//   {
//     this.currentDisplayTime += milliSec;
//     while (this.currentDisplayTime > this.tileDisplayDuration)
//     {
//       this.currentDisplayTime -= this.tileDisplayDuration;
      
//       if (this.enabled) {
//         this.currentTile++;
//         var currentColumn = this.currentTile % this.tilesHorizontal;
//         texture.offset.x = currentColumn / this.tilesHorizontal;
//         var currentRow = Math.floor( this.currentTile / this.tilesHorizontal );
        
//         texture.offset.y = this.tilesVertical - (currentRow / this.tilesVertical);

//         if (this.currentTile === this.numberOfTiles) {
//           this.currentTile = 0;
//         }

//         if (this.currentTile === 26) {
//           this.enabled = false;

//           setTimeout(() => {
//             this.enabled = true;
//           }, 2000);
//         }
//       }
//     }
//   };
// }





function Index(props) {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [formActive, setFormActive] = useState(false)
  const [albumActive, setAlbumActive] = useState(false)
  const [sceneView, setSceneView] = useState('map')
  const [objectActive, setObjectActive] = useState(false)

  const [mapInstructionsActive, setMapInstructionsActive] = useState(true)

  useEffect(() => {
    initGraphics();
    initControls();
    animate();

    // Wait until models are loaded
    // initMap()
    // resizeMap()
  }, [])

  useEffect(() => {
    if (props.locationsReady) {
      console.log('d')
      initMap()
      resizeMap()
    }
  }, [props.locationsReady])

  useEffect(() => {
    if (mapLoaded) {
      updateMarkers();
      if (loadingTimer) {
        clearTimeout(loadingTimer)
      }
      const mapLoading = document.querySelector('#map-loading');
      mapLoading.dataset.status = 'hidden';
    }
  }, [props.locations])

  const initMap = () => {
    mapboxgl.accessToken = 'pk.eyJ1Ijoid2F5d2FyZC1jcmVhdGl2ZSIsImEiOiJjbDBzbmtpZmQwYThxM2RucWRjd2p6cm0yIn0.aO9j_1cdyvLjxpPQC59RTQ';
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/wayward-creative/cl1bat6ap001e15oye221dxuf',
      center: [props.currentLocation.lng, props.currentLocation.lat],
      zoom: 12,
      minZoom: 7,
    }); 

    addMarkers();

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl
    })

    map.current.addControl(geocoder);

    const mapSearchTrigger = document.querySelector('.map-search-trigger');
    mapSearchTrigger.addEventListener('click', () => {
      // console.log('searching ')
      mapSearchTrigger.classList.remove('active');
      props.getLocations(map.current.getCenter().lng,map.current.getCenter().lat);
        
      loadingTimer = setTimeout(() => {
        const mapLoading = document.querySelector('#map-loading');
        mapLoading.dataset.status = 'active';
      }, 2000);
    })


    let getNewLocations = false;
    geocoder.on('result', (event) => {
      // Automatically search
      getNewLocations = true;
      mapSearchTrigger.classList.remove('active');
    });
    map.current.on('moveend', (e) => {
      if (getNewLocations) {
        // console.log('searching ')
        props.getLocations(map.current.getCenter().lng,map.current.getCenter().lat);
        
        loadingTimer = setTimeout(() => {
          const mapLoading = document.querySelector('#map-loading');
          mapLoading.dataset.status = 'active';
        }, 2000);

        setTimeout(() => {
          getNewLocations = false;
        }, 2000);
      }
    });

    map.current.on('zoomend', (e) => {
      // console.log('zoome end');
      // Dont get new locations
      // getNewLocations = false;
    });

    map.current.on('dragend', (e) => {
      // console.log('drag end');
      // Ask to get new locations
      getNewLocations = false;
      mapSearchTrigger.classList.add('active');
    });

    mapLoaded = true;
    console.log('m')
    props.updateModelLoading()
  }

  const addMarkers = () => {
    if (props.locations) {
      for (const location of props.locations) {
        // Create a DOM element for each marker.
        const el = document.createElement('div');

        if (location.category === 'indie' && (location.city === 'LOS ANGELES' || location.city === 'Los Angeles') ) {
          el.className = 'map-marker map-marker--orange';
        } else if (location.category === 'urban-outfitters') {
          el.className = 'map-marker map-marker--blue';
        } else if (location.category === 'target') {
          el.className = 'map-marker map-marker--red';
        }  else if (location.category === 'walmart') {
          el.className = 'map-marker map-marker--darkblue';
        } else {
          el.className = 'map-marker';
        }
        
        el.addEventListener('click', () => {
          handleMarkerClick(location._id)
        });
        
        // Add markers to the map.
        const marker = new mapboxgl.Marker(el)
        .setLngLat([location.lng, location.lat])
        .addTo(map.current);

        currentMarkers.push(marker)
      }
    }
  }

  const updateMarkers = () => {
    currentMarkers.forEach(marker => {
      marker.remove();
    });

    addMarkers();
  }


  const handleMarkerClick = (id) => {
    props.viewMarker(id);
  }

  const initGraphics = () => {
    // clock = new THREE.Clock();
    scene = new THREE.Scene()
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    
    if (window.innerWidth < 800) {
      cameraZ = 5;
      cameraY = 3;
    } else {
      cameraZ = 3.5;
      cameraY = 2.5;
    }

    camera.position.y = cameraY;
    camera.position.z = cameraZ;
    camera.mapPos = new THREE.Vector3(0, cameraY, cameraZ);
    if (window.innerWidth < 800) {
      camera.posterPos = new THREE.Vector3(4.5, cameraY, cameraZ);
    } else {
      camera.posterPos = new THREE.Vector3(4.5, cameraY + 0.5, cameraZ);
    }
    camera.targetPosition = camera.position;

    renderer = new THREE.WebGLRenderer({alpha: true, antialias: true})
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.shadowMap.enabled = true;
    wrapper = document.querySelector('#main-scene');
    wrapper.appendChild(renderer.domElement)
    window.addEventListener('resize', resize, false);

    // CSS RENDERER
    const containerCSS = document.getElementById( 'containerCSS' );
    rendererCSS = new CSS3DRenderer();
    rendererCSS.setSize( window.innerWidth, window.innerHeight );
    containerCSS.appendChild( rendererCSS.domElement );

    // RAYCASTER //
    raycaster = new THREE.Raycaster();
		pointer = new THREE.Vector2();

    // LIGHTS
    const ambLight = new THREE.AmbientLight(0xFFFFFF, 0.3);
    scene.add(ambLight);

    const dirLight = new THREE.DirectionalLight(0xFFFFFF, 0.9);
    dirLight.position.set(7.5, 3, 5);
    dirLight.target.position.set(7, 3, 0);
    dirLight.castShadow = true;
    dirLight.shadow.bias = -0.005;
    dirLight.shadow.camera.top = 6;
    dirLight.shadow.camera.bottom = -6;
    dirLight.shadow.camera.left = -6;
    dirLight.shadow.camera.right = 6;
    dirLight.shadow.camera.near = 0.1;
    dirLight.shadow.camera.far = 20;
    scene.add(dirLight);
    scene.add(dirLight.target);
    // const helper = new THREE.DirectionalLightHelper( dirLight, 5 );
    // scene.add( helper );
    // scene.add( new THREE.CameraHelper( dirLight.shadow.camera ) );

    // const controls = new OrbitControls( camera, renderer.domElement );

    const lobbyOverhead1 = new THREE.PointLight( 0xFFFFFF, 0.06, 100 );
    lobbyOverhead1.position.set( 5, 3, 4.5 );
    scene.add( lobbyOverhead1 );
    // const lobbyOverhead1Helper = new THREE.PointLightHelper( lobbyOverhead1, 1 );
    // scene.add( lobbyOverhead1Helper );



    // MODELS
    textureLoader = new THREE.TextureLoader();


    const easterEggZoomX = camera.posterPos.x;
    const easterEggZoomY = 3;
    const easterEggZoomZ = 2.5;

    modelLoader = new GLTFLoader();
    modelLoader.load('/models/rhcp-wall.glb', gltf => {
      store = gltf.scene;

      store.traverse(node => {
        if ( node.isMesh ) { 
          node.castShadow = true;
          node.receiveShadow = true;
        }
      });

      recordPlaying = store.getObjectByName('record-now-playing');

      const albumStandDesktop = store.getObjectByName('album-stand-desktop');
      const albumStandMobile = store.getObjectByName('album-stand-mobile');

      if (window.Modernizr.touchevents) {
        albumStandDesktop.visible = false;
      } else {
        albumStandMobile.visible = false;
      }

      const recordStackCenter = store.getObjectByName('record-stack-center');
      if (window.innerWidth < 800) {
        recordStackCenter.position.y = recordStackCenter.position.y - 0.3;
      }

      const recordPlayerStand = store.getObjectByName('record-player-stand');
      if (window.innerWidth < 800) {
        recordPlayerStand.position.x = recordPlayerStand.position.x + 0.2;
      }

      const wallStoreBanner = store.getObjectByName('online-store-banner_interactive');
      if (window.innerWidth < 800) {
        wallStoreBanner.position.y = wallStoreBanner.position.y + 1;
      }

      
      flag = store.getObjectByName('flag');
      flag.startPosition = new THREE.Vector3();
      flag.startPosition.copy(flag.position);
      flag.targetPosition = flag.position;
      flag.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ - 0.5);
      flag.startRotY = flag.rotation.y;
      flag.canRotate = true;
      easterEggs.push(flag)

      flyer1 = store.getObjectByName('flyer-1');
      flyer1.startPosition = new THREE.Vector3();
      flyer1.startPosition.copy(flyer1.position);
      flyer1.targetPosition = flyer1.position;
      flyer1.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ);
      flyer1.startRotY = flyer1.rotation.y;
      flyer1.canRotate = true;
      easterEggs.push(flyer1)

      flyer2 = store.getObjectByName('flyer-2_interactive');
      flyer2.startPosition = new THREE.Vector3();
      flyer2.startPosition.copy(flyer2.position);
      flyer2.targetPosition = flyer2.position;
      flyer2.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ);
      flyer2.startRotY = flyer2.rotation.y;
      flyer2.canRotate = true;
      easterEggs.push(flyer2)

      flyer3 = store.getObjectByName('flyer-3_interactive');
      flyer3.startPosition = new THREE.Vector3();
      flyer3.startPosition.copy(flyer3.position);
      flyer3.targetPosition = flyer3.position;
      flyer3.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ);
      flyer3.startRotY = flyer3.rotation.y;
      flyer3.canRotate = true;
      easterEggs.push(flyer3)

      posterBand1 = store.getObjectByName('poster-band-1');
      posterBand1.startPosition = new THREE.Vector3();
      posterBand1.startPosition.copy(posterBand1.position);
      posterBand1.targetPosition = posterBand1.position;
      posterBand1.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ);
      posterBand1.startRotY = posterBand1.rotation.y;
      posterBand1.canRotate = true;
      easterEggs.push(posterBand1)

      posterBand2 = store.getObjectByName('poster-band-2');
      posterBand2.startPosition = new THREE.Vector3();
      posterBand2.startPosition.copy(posterBand2.position);
      posterBand2.targetPosition = posterBand2.position;
      posterBand2.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ);
      posterBand2.startRotY = posterBand2.rotation.y;
      posterBand2.canRotate = true;
      easterEggs.push(posterBand2)

      bandPhoto1 = store.getObjectByName('band-photo-1');
      bandPhoto1.startPosition = new THREE.Vector3();
      bandPhoto1.startPosition.copy(bandPhoto1.position);
      bandPhoto1.targetPosition = bandPhoto1.position;
      bandPhoto1.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ);
      bandPhoto1.startRotY = bandPhoto1.rotation.y;
      bandPhoto1.canRotate = true;
      easterEggs.push(bandPhoto1)

      bandPhoto2 = store.getObjectByName('band-photo-2');
      bandPhoto2.startPosition = new THREE.Vector3();
      bandPhoto2.startPosition.copy(bandPhoto2.position);
      bandPhoto2.targetPosition = bandPhoto2.position;
      bandPhoto2.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ);
      bandPhoto2.startRotY = bandPhoto2.rotation.y;
      bandPhoto2.canRotate = true;
      easterEggs.push(bandPhoto2)

      bandPhoto3 = store.getObjectByName('band-photo-3');
      bandPhoto3.startPosition = new THREE.Vector3();
      bandPhoto3.startPosition.copy(bandPhoto3.position);
      bandPhoto3.targetPosition = bandPhoto3.position;
      bandPhoto3.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ);
      bandPhoto3.startRotY = bandPhoto3.rotation.y;
      bandPhoto3.canRotate = true;
      easterEggs.push(bandPhoto3)

      hudiniPhoto = store.getObjectByName('hudini-photo');
      hudiniPhoto.startPosition = new THREE.Vector3();
      hudiniPhoto.startPosition.copy(hudiniPhoto.position);
      hudiniPhoto.targetPosition = hudiniPhoto.position;
      hudiniPhoto.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ);
      hudiniPhoto.startRotY = hudiniPhoto.rotation.y;
      hudiniPhoto.canRotate = true;
      easterEggs.push(hudiniPhoto)

      grammy = store.getObjectByName('grammy');
      grammy.startPosition = new THREE.Vector3();
      grammy.startPosition.copy(grammy.position);
      grammy.targetPosition = grammy.position;
      grammy.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ);
      grammy.startRotY = grammy.rotation.y;
      grammy.canRotate = true;
      easterEggs.push(grammy)

      sock = store.getObjectByName('sock_interactive');
      sock.startPosition = new THREE.Vector3();
      sock.startPosition.copy(sock.position);
      sock.targetPosition = sock.position;
      sock.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ);
      sock.startRotY = sock.rotation.y;
      sock.canRotate = true;
      easterEggs.push(sock)

      licensePlate = store.getObjectByName('license-plate_interactive');
      licensePlate.startPosition = new THREE.Vector3();
      licensePlate.startPosition.copy(licensePlate.position);
      licensePlate.targetPosition = licensePlate.position;
      licensePlate.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ);
      licensePlate.startRotY = licensePlate.rotation.y;
      licensePlate.canRotate = true;
      easterEggs.push(licensePlate)

      vinylBanner = store.getObjectByName('vinyl-banner_interactive');
      vinylBanner.startPosition = new THREE.Vector3();
      vinylBanner.startPosition.copy(vinylBanner.position);
      vinylBanner.targetPosition = vinylBanner.position;
      vinylBanner.zoomPosition = new THREE.Vector3(0, 2.5, 2);
      vinylBanner.startRotY = vinylBanner.rotation.y;
      vinylBanner.canRotate = false;
      easterEggs.push(vinylBanner)


      tvScreen = store.getObjectByName('tv-screen_interactive')
      const videoGeo = new THREE.BoxBufferGeometry( 0.8, 0.5, 0.01 );
      const tvVideoElement = document.getElementById( `video-1` );
      const vid1Texture = new THREE.VideoTexture( tvVideoElement );
      const vid1Material = new THREE.MeshLambertMaterial( {map:  vid1Texture});
      const tvVideoMesh = new THREE.Mesh(videoGeo, vid1Material);

      const tvScreenPosition = tvScreen.getWorldPosition(new THREE.Vector3())
      const tvScreenRotation = tvScreen.getWorldQuaternion(new THREE.Quaternion())
      tvVideoMesh.position.set(tvScreenPosition.x, tvScreenPosition.y, tvScreenPosition.z + 0.01)
      // tvVideoMesh.quaternion.copy(tvScreenRotation)
      tvVideoMesh.rotation.y = 0.36;
      tvVideoElement.play()
      scene.add(tvVideoMesh)

      store.traverse(node => {
        if (node.name.includes('interactive')) {
          objects.push(node)
        }
      })

      // // Explosion
      // const neonSignBase = store.getObjectByName('unlimited-love-neon-sign_base');
      // const explosionTexture = new THREE.TextureLoader().load( '/models/sprites/neon-gif.png' );
      // const explosionMaterial = new THREE.SpriteMaterial({map: explosionTexture});
      // explosionSprite = new THREE.Sprite( explosionMaterial );
      // explosionSprite.scale.set(2.87,2.87,2.87);
      // // tilesHoriz, tilesVert, numTiles, tileDispDuration
      // explosionAnimator = new TextureAnimator( explosionTexture, 27, 1, 27, 60 );
      // explosionSprite.visible = true;
      // explosionSprite.position.set(neonSignBase.position.x + 0.094, neonSignBase.position.y, neonSignBase.position.z + 0.01)
      // explosionAnimator.enabled = false;
      // scene.add(explosionSprite);


      scene.add(store)
      screenMesh.visible = true;

      // initMap()
      // resizeMap()

      console.log('3')
    })


    // THEATER CSS
    const obj = new THREE.Object3D

    const div = document.createElement( 'div' );
    const divMap = document.querySelector( '#map-container' );
    
    if (window.innerWidth < 800) {
      div.style.width = '600px';
      div.style.height = '1000px';
    } else {
      div.style.width = '600px';
      div.style.height = '600px';
    }
    // div.style.backgroundColor = 'blue';
    div.style.opacity = '1'
    div.id = 'map-screen';

    div.appendChild(divMap)

    cssScreen = new CSS3DObject( div );
    cssScreen.scale.set(0.005, 0.005, 0.005);
    cssScreen.rotation.y = 0;

    obj.add( cssScreen );

    // make an invisible plane for the DOM element to chop
    // clip a WebGL geometry with it.
    var material = new THREE.MeshPhongMaterial({
      opacity	: 0.15,
      blending: THREE.AdditiveBlending,
      color: 0x000000
      // side	: THREE.DoubleSide,
      // map: textureLoader.load('/models/textures/map-texture-overlay-5.png')
    });
    if (window.innerWidth < 800) {
      var geometry = new THREE.BoxGeometry( 3, 5, 0.001 );
    } else {
      var geometry = new THREE.BoxGeometry( 3, 3, 0.0001 );
    }
    screenMesh = new THREE.Mesh( geometry, material );
    screenMesh.castShadow = true;
    screenMesh.receiveShadow = true;
    screenMesh.visible = false;
    obj.lightShadowMesh = screenMesh
    obj.add( screenMesh );

    obj.position.set(0,2.7,0.55)
    scene.add(obj)
  }

  const initControls = () => {
    // wrapper.addEventListener( 'pointermove', onPointerMove );
    // wrapper.addEventListener( 'click', handleClick );

    const threeWrapper = document.querySelector('#three-wrap');
    threeWrapper.addEventListener( 'pointermove', onPointerMove );
    threeWrapper.addEventListener( 'click', handleClick );

    const objectCover = document.querySelector('#object-cover');
    objectCover.addEventListener( 'pointermove', objectPointerMove );
    objectCover.addEventListener( 'mousedown', objectPointerStart );
    objectCover.addEventListener( 'mouseup', objectPointerEnd );
    objectCover.addEventListener( 'touchstart', objectPointerStart );
    objectCover.addEventListener( 'touchend', objectPointerEnd );
  }

  const animate = () => {
    // const delta = clock.getDelta();
    requestAnimationFrame(animate)

    camera.position.lerp(camera.targetPosition, 0.09);

    if (recordPlaying) {
      recordPlaying.rotation.y -= 0.04;
    }

    easterEggs.forEach(object => {
      object.position.lerp(object.targetPosition, 0.09);
    })


    // if (explosionAnimator) {
    //   explosionAnimator.update(1000 * delta);
    // }


    rendererCSS.render( scene, camera );
    renderer.render(scene,camera)    
  }

  const resize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight)
    rendererCSS.setSize(window.innerWidth, window.innerHeight);

    resizeMap();
  }

  const resizeMap = () => {
    const mapControls = document.querySelector('#map-controls')
    const mapScreen = document.querySelector('#map-screen')
    const mapContainer = document.querySelector('#map-container')
    
    const screenBox = mapScreen.getBoundingClientRect()
    const containerBox = mapContainer.getBoundingClientRect();

    mapControls.style.width = `${screenBox.width}px`;
    mapControls.style.height = `${screenBox.width}px`;

    const mapCanvas = mapContainer.querySelector('.mapboxgl-canvas-container');
    if (map.current && mapCanvas) {
      mapCanvas.style.width = `${containerBox.width}px`;
      mapCanvas.style.height = `${containerBox.height}px`;
      mapCanvas.style.position = `absolute`;
      mapCanvas.style.top = `50%`;
      mapCanvas.style.left = `50%`;
      mapCanvas.style.transform = `translate(-50%,-50%)`;
      mapCanvas.style.border = '2px solid red;';

      const searchContainer = document.querySelector('.mapboxgl-ctrl-geocoder');
      const mapLoading = document.querySelector('#map-loading');
      const mapSearchTrigger = document.querySelector('#map-search-trigger');
      if (window.innerHeight < 900) {
        cssScreen.scale.set(0.008,0.008, 0.008);
        searchContainer.classList.add('controls-resized');
        mapLoading.classList.add('map-loading-resized');
        mapSearchTrigger.classList.add('map-trigger-resized');
      } else {
        cssScreen.scale.set(0.005,0.005, 0.005);
        searchContainer.classList.remove('controls-resized');
        mapLoading.classList.remove('map-loading-resized');
        mapSearchTrigger.classList.remove('map-trigger-resized');
      }

      if (window.Modernizr.touchevents) {
        if (window.innerHeight < 900 && window.innerWidth < 800) {
          cssScreen.scale.set(0.01,0.01, 0.01);
          searchContainer.classList.add('controls-resized');
          mapLoading.classList.add('map-loading-resized');
          mapSearchTrigger.classList.add('map-trigger-resized');
        }
        if (window.innerWidth < 800 && window.innerHeight > 900) {
          // Mid size tablets
          cssScreen.scale.set(0.008,0.008, 0.008);
          searchContainer.classList.add('controls-resized');
          mapLoading.classList.add('map-loading-resized');
          mapSearchTrigger.classList.add('map-trigger-resized');
        }
        if (window.innerWidth < 380) {
          // Small phones
          cssScreen.scale.set(0.011,0.011, 0.011);
          searchContainer.classList.add('controls-resized');
          mapLoading.classList.add('map-loading-resized');
          mapSearchTrigger.classList.add('map-trigger-resized');
        }
      }

        
      map.current.resize()
    }
  }

  const setTargetLocation = (e) => {
    pointer.x = ( e.clientX / renderer.domElement.clientWidth ) * 2 - 1;
    pointer.y = - ( e.clientY / renderer.domElement.clientHeight ) * 2 + 1;

    raycaster.setFromCamera( pointer, camera );
    
    if (store) {
      const intersectsObjects = raycaster.intersectObjects(objects);
      
      if (intersectsObjects.length > 0) {
        pointer.target = intersectsObjects[ 0 ];
      } else {
        pointer.target = null;
      }
    }
  }

  const onPointerMove = (e) => {
    setTargetLocation(e)
  }

  const handleClick = (e) => {
    setTargetLocation(e)
    
    if (pointer && pointer.target && pointer.target.object) {
      if (pointer.target.object.name.includes('flag')) {
        flag.targetPosition = flag.zoomPosition;
        activeObject = flag;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('flyer-1')) {
        flyer1.targetPosition = flyer1.zoomPosition;
        activeObject = flyer1;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('flyer-2')) {
        flyer2.targetPosition = flyer2.zoomPosition;
        activeObject = flyer2;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('flyer-3')) {
        flyer3.targetPosition = flyer3.zoomPosition;
        activeObject = flyer3;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('poster-band-1')) {
        posterBand1.targetPosition = posterBand1.zoomPosition;
        activeObject = posterBand1;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('poster-band-2')) {
        posterBand2.targetPosition = posterBand2.zoomPosition;
        activeObject = posterBand2;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('band-photo-1')) {
        bandPhoto1.targetPosition = bandPhoto1.zoomPosition;
        activeObject = bandPhoto1;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('band-photo-2')) {
        bandPhoto2.targetPosition = bandPhoto2.zoomPosition;
        activeObject = bandPhoto2;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('band-photo-3')) {
        bandPhoto3.targetPosition = bandPhoto3.zoomPosition;
        activeObject = bandPhoto3;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('hudini-photo')) {
        hudiniPhoto.targetPosition = hudiniPhoto.zoomPosition;
        activeObject = hudiniPhoto;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('grammy')) {
        grammy.targetPosition = grammy.zoomPosition;
        activeObject = grammy;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('sock')) {
        sock.targetPosition = sock.zoomPosition;
        activeObject = sock;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('license-plate')) {
        licensePlate.targetPosition = licensePlate.zoomPosition;
        activeObject = licensePlate;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('vinyl-banner')) {
        vinylBanner.targetPosition = vinylBanner.zoomPosition;
        activeObject = vinylBanner;
        setObjectActive(true)

        const mapControls = document.querySelector('#map-controls');
        mapControls.dataset.posterleft = 'true';
      } else if (pointer.target.object.name.includes('online-store-banner')) {
        const rhcpstorelink = document.querySelector('#online-store-link');
        rhcpstorelink.click();
      } else if (pointer.target.object.name.includes('tv')) {
        const tvLink = document.querySelector('#tv-link');
        tvLink.click();
      } 
      
    }
  }

  const returnObject = () => {
    easterEggs.forEach(item => {
      item.targetPosition = item.startPosition;
    })
    activeObject.rotation.y = activeObject.startRotY;
    activeObject = null;
    setObjectActive(false)

    const mapControls = document.querySelector('#map-controls');
    mapControls.dataset.posterleft = 'false';
  }

  // Object rotation
  var mouseDown = false,
      mouseX = 0,
      mouseY = 0;
  const objectPointerMove = (e) => {
    if (!mouseDown) {
      return;
    }
    e.preventDefault();
    var deltaX = e.clientX - mouseX,
        deltaY = e.clientY - mouseY;
    mouseX = e.clientX;
    mouseY = e.clientY;
    rotateObject(deltaX, deltaY);
  }

  const objectPointerStart = (e) => {
    e.preventDefault();
    mouseDown = true;
    mouseX = e.clientX;
    mouseY = e.clientY;
  }
  const objectPointerEnd = (e) => {
    e.preventDefault();
    mouseDown = false;
  }

  function rotateObject(deltaX, deltaY) {
    if (activeObject && activeObject.canRotate) {
      if (!isNaN(deltaX) && !isNaN(deltaY)) {
        activeObject.rotation.y += deltaX / 100;
      }
    }
  }

  const openPinForm = () => {
    setFormActive(true)
  }

  const closePinForm = () => {
    setFormActive(false)
  }

  const viewAlbum = () => {
    setAlbumActive(true)
  }

  const closeAlbum = () => {
    setAlbumActive(false)
  }

  const navigate = (e) => {
    const direction = e.target.dataset.direction;
    if (direction === 'right') {
      setSceneView('posters');
      camera.targetPosition = camera.posterPos;
    } else {
      setSceneView('map');
      camera.targetPosition = camera.mapPos;
    }
  }

  const closeMapInstructions = () => {
    setMapInstructionsActive(false);
    // Trigger audio play if it hasn't started automatically
    document.dispatchEvent(new CustomEvent('patchPlayAudio'));
  }

  return(
    <div className={styles.wrapper} data-scene={sceneView}>
      {/* <Map
        locations={props.locations}
        viewMarker={props.viewMarker}
      /> */}

      <div id='three-wrap' className={styles.threeWrap}>
        <div id='containerCSS' className={styles.cssCanvas}></div>
        <div id='main-scene' className={styles.webGLCanvas} data-pointer={sceneView === 'posters' ? 'true' : 'false'}></div>
      </div>

      <div ref={mapContainer} id='map-container' className={styles.mapContainer}>
        {/* <div ref={map} id='map' className={styles.map}></div> */}
        <div id='map-loading' className='map-loading' data-status='hidden'>Loading locations...</div>
        <div id='map-search-trigger' className='map-search-trigger'>Search this area</div>
      </div>

      <div id='map-controls' className={styles.controls}>
        <div onClick={viewAlbum} className={styles.albumTrigger}></div>

        <div onClick={openPinForm}  className={styles.pinTrigger}>
          <div className={styles.pinTriggerText}>
            DROP A PIN TO SHARE WHICH EDITION YOU PICKED UP & WHERE
          </div>
          <button className='btn btn--circle btn--circle--md btn--primary'>
            +
          </button>
        </div>
      </div>

      <button onClick={navigate} className={`${styles.navBtn} btn btn--circle btn--circle--sm btn--clear`} data-direction='left' data-active={sceneView === 'posters'}>
        <IconAngleLeft />
      </button>
      <button onClick={navigate} className={`${styles.navBtn} btn btn--circle btn--circle--sm btn--clear`} data-direction='right' data-active={sceneView === 'map'}>
        <IconAngleRight />
      </button>


      <div className={styles.objectOverlay} data-active={objectActive}>
        <div id='object-cover' className={styles.objectCover}></div>
        <div onClick={returnObject} className={styles.objectClose}>X CLOSE</div>
        {/* <a href={objectURL} target="_blank" rel="noreferrer" className={styles.objectBuy}>Buy Now</a> */}
      </div>
      <a id='online-store-link' className={styles.onlineStoreLink} href='http://rhcp.lnk.to/unlimitedlovestore' target='_blank' rel='noreferrer'>.</a>
      <a id='tv-link' className={styles.onlineStoreLink} href='https://www.instagram.com/p/Cb0LJRbr4J6/' target='_blank' rel='noreferrer'>.</a>

      {albumActive
        ? <Album closeAlbum={closeAlbum} />
        : null
      }

      {formActive
        ? <PinForm closePinForm={closePinForm} locations={props.locations} />
        : null
      }

      {mapInstructionsActive
        ? <div className='info-window info-window--sm'>
          
            <p>
              Enter your location into the search bar to find record stores near you. Search by store, city, or zip.
            </p>
            <br/>
            <button className='btn btn--primary btn--block btn--lg' onClick={closeMapInstructions}>GOT IT</button>

            <div className='info-window__close' onClick={closeMapInstructions}>X CLOSE</div>
          </div>
        : null
      }


      <video id="video-1" className='video3D' crossOrigin="anonymous" playsInline muted loop>
        <source src="/models/video/static-2-2.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
      </video>
    </div>
  )
}

export default Index;