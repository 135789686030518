import styles from './Album.module.scss'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { useState, useEffect } from 'react';

let scene,camera,renderer,wrapper,modelLoader, controls;

let album, albumFront, albumBack;
let openPosition, closedPosition, openQuaternion, closedQuaternion;

function Foreground(props) {
  const [gatefoldOpen, setGatefoldOpen] = useState(false)
  const [instructionsActive, setInstructionsActive] = useState(true)

  useEffect(() => {
    initGraphics();
    animate();
  }, [])

  const initGraphics = () => {
    scene = new THREE.Scene()
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    camera.position.z = 0.5;

    renderer = new THREE.WebGLRenderer({alpha: true, antialias: true, preserveDrawingBuffer: true})
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.outputEncoding = THREE.sRGBEncoding;
    wrapper = document.querySelector('#webgl-1');
    wrapper.appendChild(renderer.domElement)
    window.addEventListener('resize', resize, false);

    controls = new OrbitControls( camera, renderer.domElement );
    

    // LIGHTS
    const ambLight = new THREE.AmbientLight(0xFFFFFF, 1);
    scene.add(ambLight);

    const dirLight = new THREE.DirectionalLight(0xFFFFFF, 1);
    dirLight.position.set(-5, 10, 5);
    dirLight.target.position.set(0, 0, 0);
    scene.add(dirLight);
    scene.add(dirLight.target);

    // MODELS
    modelLoader = new GLTFLoader();
    const albumGeo = new THREE.BoxGeometry(1,1,0.1)
    const albumMat = new THREE.MeshNormalMaterial();
    // album = new THREE.Mesh(albumGeo, albumMat)

    let vinylUrl;
    // if (window.location.href.includes('heroku') || window.location.href.includes('localhost')) {
    //   vinylUrl = '/models/rhcp-vinyl_dream-canteen.glb'
    // } else {
    //   vinylUrl = '/models/rhcp-vinyl_with-record.glb'
    // }
    vinylUrl = 'https://d39x71nefpqfvh.cloudfront.net/models/rhcp-vinyl_dream-canteen.glb'

    modelLoader.load(vinylUrl, gltf => {
      scene.add(gltf.scene)

      openPosition = new THREE.Vector3(0.1524, 0, 0)
      openQuaternion = new THREE.Quaternion(0, -0.9974949866040544, 0, 0.0707372016677029);
      closedPosition = new THREE.Vector3(0, 0, 0)
      closedQuaternion = new THREE.Quaternion(0, 0, 0, 1);

      album = scene.getObjectByName( "package", true );
      album.targetPosition = album.position;

      albumFront = scene.getObjectByName( "front", true );
      albumFront.targetQuaternion = albumFront.quaternion;

      albumBack = scene.getObjectByName( "back", true );
    })
  }

  const animate = () => {
    requestAnimationFrame(animate)

    if (album && albumFront) {
      album.position.lerp(album.targetPosition, 0.09)
      albumFront.quaternion.slerp(albumFront.targetQuaternion, 0.09)
    }

    renderer.render(scene,camera)    
  }

  const resize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight)
  }

  const openGatefold = () => {
    albumFront.targetQuaternion = openQuaternion;
    album.targetPosition = openPosition;

    setGatefoldOpen(true)
  }

  const closeGatefold = () => {
    albumFront.targetQuaternion = closedQuaternion;
    album.targetPosition = closedPosition;

    setGatefoldOpen(false)
  }

  const closeInstructions = () => {
    setInstructionsActive(false)
  }

  return(
    <div className={styles.wrapper}>
      <div id='webgl-1' className={styles.foreground}></div>
      {/* {!instructionsActive && (window.location.href.includes('heroku') || window.location.href.includes('localhost')) &&
        <div onClick={props.closeAlbum} className={styles.closeDc}>X CLOSE</div>
      }
      {!instructionsActive && (!window.location.href.includes('heroku') && !window.location.href.includes('localhost')) &&
        <div onClick={props.closeAlbum} className={styles.close}>X CLOSE</div>
      } */}
      {!instructionsActive &&
        <div onClick={props.closeAlbum} className={styles.closeDc}>X CLOSE</div>
      }

      {instructionsActive
        ? null
        :  <div className={styles.controls}>
            <button className='btn btn--primary' onClick={gatefoldOpen ? closeGatefold : openGatefold}>{gatefoldOpen ? 'SHUT' : 'OPEN'}</button>
          </div>
      }

      {instructionsActive
        ? <div className='info-window info-window--sm'>
            <img src='/icons/manipulator.png' id='manipulator' />

            <p>
              Grab and roate to interact
            </p>
            <br/>
            <button className='btn btn--primary btn--block btn--lg' onClick={closeInstructions}>GOT IT</button>

            <div className='info-window__close' onClick={closeInstructions}>X CLOSE</div>
          </div>
        : null
      }
    </div>
  )
}

export default Foreground;