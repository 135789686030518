import React from 'react'
import styles from './LocationDc.module.scss'
import IconDirections from '../Icons/Directions'

const location = (props) => (
  <div className={styles.wrapper} data-active={props.markerActive} data-special={props.location.isSpecial}>
    {/* <div className={styles.header}>
      <div onClick={props.closeMarker} className={styles.close}>X</div>
    </div> */}
    <div className={styles.content}>
      <div onClick={props.closeMarker} className={styles.close}>X CLOSE</div>
      <div className={styles.scrollWrap}>
        <div className={styles.addressWrapper}>
          <div className={styles.address}>
            {props.location.name}<br/>
            {props.location.address}<br/>
            {props.location.address_2
              ? <div>{props.location.address_2}<br/></div>
              : null
            }
            {props.location.city}, {props.location.state} {props.location.zip}
          </div>  
          <a href={`https://www.google.com/maps/dir//${props.location.name}+${props.location.address}+${props.location.city}+${props.location.state}+${props.location.zip}`} className={styles.directions} target='_blank' rel='noreferrer'>
            <IconDirections />
            <div>
              Directions
            </div>
          </a>
        </div>

        <div className={styles.section} data-role='vinyl'>
          <h2>Limited Editions</h2>
          <div className={styles.vinylWrapper}>
            {props.location.category === 'target'
              ?<div className={styles.vinylImgWrapper}>
                <img src='https://d39x71nefpqfvh.cloudfront.net/images/vinyl-dc/vinyl_yellow.png' alt='vinyl red' />
              </div>
              : null
            }
          
            {props.location.category === 'indie'
              ? 
                <React.Fragment>
                  <div className={styles.vinylImgWrapper}>
                    <img src='https://d39x71nefpqfvh.cloudfront.net/images/vinyl-dc/vinyl_pink.png' />
                  </div>
                  <div className={styles.vinylImgWrapper}>
                    <img src='https://d39x71nefpqfvh.cloudfront.net/images/vinyl-dc/vinyl_gold.png' />
                  </div>
                </React.Fragment>
              : null
            }
            <div className={styles.vinylImgWrapper}>
              <img src='https://d39x71nefpqfvh.cloudfront.net/images/vinyl-dc/vinyl_standard.png' alt='vinyl standard' />
            </div>
          </div>
          {props.location.isSpecial &&
            <div className={styles.promoDisclaimer}>
              <p>
                * RHCP Promotion Store!<br/>
                Call and check with the store for more details.
              </p>
            </div>
          }
          {props.location.kraftBag &&
            <div className={styles.promoDisclaimer}>
              <p>
                **Exclusive RHCP Paper Vinyl Bag Available While Supplies Last
              </p>
            </div>
          }
        </div>
        
        <div className={styles.section} data-role='pin-feed'>
          <h2>Fan Feed</h2>
          <div className={styles.pinFeed}>
            {props.location.pins
              ? props.location.pins.map((pin, i) => (
                  <div key={`pin_${i}`} className={styles.pinWrapper}>
                    <div className={styles.pinAspect}>
                      <div key={`pin_${i}`} className={styles.pin}>
                        <img src={pin.image_url}  alt='fan pin' />
                      </div>
                    </div>
                  </div>
                ))
              : null
            }
          </div>
        </div>
      </div>

    </div>
  </div>
)

export default location