/* eslint import/no-webpack-loader-syntax: off */
import { useState, useEffect, useRef } from 'react'
import styles from '../../components/Scene/SceneDc.module.scss'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import mapboxgl from '!mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import PinForm from './PinForm'
import Album from './Album'
import IconAngleLeft from '../../components/Icons/AngleLeft'
import IconAngleRight from '../../components/Icons/AngleRight'
import VideoModal from '../../components/Scene/VideoModal'

// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

let scene,camera,renderer,wrapper,modelLoader,textureLoader,raycaster,pointer;
let rendererCSS,screenMesh,cssScreen;
// let map, store;
let store;


let mapLoaded = false;
const objects = [];
const easterEggs = [];
let activeObject;
let cameraZ, cameraY;

let recordPlaying;

let bass,hat,setlist,cassette,drum,tshirt,vinylPackage,vinylBanner,icecreamBanner,penant;
let tvScreen;

const currentMarkers = [];

let loadingTimer; 


function Index(props) {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [formActive, setFormActive] = useState(false)
  const [albumActive, setAlbumActive] = useState(false)
  const [sceneView, setSceneView] = useState('map')
  const [objectActive, setObjectActive] = useState(false)
  const [icecreamActive, setIcecreamActive] = useState(false)

  const [videoActive, setVideoActive] = useState(false)

  const [mapInstructionsActive, setMapInstructionsActive] = useState(true)

  useEffect(() => {
    initGraphics();
    initControls();
    animate();

    // Wait until models are loaded
    // initMap()
    // resizeMap()
  }, [])

  useEffect(() => {
    if (props.locationsReady) {
      console.log('d')
      initMap()
      setTimeout(() => {
        resizeMap()
      }, 2000);
      resizeMap()
    }
  }, [props.locationsReady])

  useEffect(() => {
    if (props.locationsReady && props.newUserLocation && map.current) {
      // console.log('jmp')
      map.current.jumpTo({center: [props.currentLocation.lng, props.currentLocation.lat], zoom: 11})
    }
  }, [props.locationsReady, props.newUserLocation])

  useEffect(() => {
    if (mapLoaded) {
      updateMarkers();
      if (loadingTimer) {
        clearTimeout(loadingTimer)
      }
      const mapLoading = document.querySelector('#map-loading');
      mapLoading.dataset.status = 'hidden';
    }
  }, [props.locations])

  const initMap = () => {
    mapboxgl.accessToken = 'pk.eyJ1Ijoid2F5d2FyZC1jcmVhdGl2ZSIsImEiOiJjbDBzbmtpZmQwYThxM2RucWRjd2p6cm0yIn0.aO9j_1cdyvLjxpPQC59RTQ';
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/wayward-creative/cl1bat6ap001e15oye221dxuf',
      center: [props.currentLocation.lng, props.currentLocation.lat],
      zoom: 12,
      minZoom: 7,
    }); 

    addMarkers();

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl
    })

    map.current.addControl(geocoder);

    const mapSearchTrigger = document.querySelector('.map-search-trigger');
    mapSearchTrigger.addEventListener('click', () => {
      // console.log('searching ')
      mapSearchTrigger.classList.remove('active');
      props.getLocations(map.current.getCenter().lng,map.current.getCenter().lat);
        
      loadingTimer = setTimeout(() => {
        const mapLoading = document.querySelector('#map-loading');
        mapLoading.dataset.status = 'active';
      }, 2000);
    })

    let getNewLocations = false;
    geocoder.on('result', (event) => {
      console.log(event)
      // Automatically search
      getNewLocations = true;
      mapSearchTrigger.classList.remove('active');
    });
    map.current.on('moveend', (e) => {
      if (getNewLocations) {
        // console.log('searching ')
        props.getLocations(map.current.getCenter().lng,map.current.getCenter().lat);
        
        loadingTimer = setTimeout(() => {
          const mapLoading = document.querySelector('#map-loading');
          mapLoading.dataset.status = 'active';
        }, 2000);

        setTimeout(() => {
          getNewLocations = false;
        }, 2000);
      }
    });

    map.current.on('zoomend', (e) => {
      // console.log('zoome end');
      // Dont get new locations
      // getNewLocations = false;
    });

    map.current.on('dragend', (e) => {
      // console.log('drag end');
      // Ask to get new locations
      getNewLocations = false;
      mapSearchTrigger.classList.add('active');
    });

    mapLoaded = true;
    console.log('m')
    props.updateModelLoading()
  }

  const addMarkers = () => {
    if (props.locations) {
      for (const location of props.locations) {
        // Create a DOM element for each marker.
        const el = document.createElement('div');

        if (location.address.includes('6200 HOLLYWOOD BLVD') || location.address.includes('900 N Sycamore Ave') || location.address.includes('13616 VENTURA BLVD.') ) {
          el.className = 'map-marker map-marker--gold';
        } else if (location.category === 'target') {
          el.className = 'map-marker map-marker--yellow';
        } else {
          el.className = 'map-marker map-marker--gold';
        }
        
        el.addEventListener('click', () => {
          handleMarkerClick(location._id)
        });
        
        // Add markers to the map.
        const marker = new mapboxgl.Marker(el)
        .setLngLat([location.lng, location.lat])
        .addTo(map.current);

        currentMarkers.push(marker)
      }
    }
  }

  const updateMarkers = () => {
    currentMarkers.forEach(marker => {
      marker.remove();
    });

    addMarkers();
  }


  const handleMarkerClick = (id) => {
    props.viewMarker(id);
  }

  const initGraphics = () => {
    // clock = new THREE.Clock();
    scene = new THREE.Scene()
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    
    if (window.innerWidth < 800) {
      cameraZ = 5;
      cameraY = 3;
    } else {
      cameraZ = 3.5;
      cameraY = 2.5;
    }

    camera.position.y = cameraY;
    camera.position.z = cameraZ;
    camera.mapPos = new THREE.Vector3(0, cameraY, cameraZ);
    if (window.innerWidth < 800) {
      camera.posterPos = new THREE.Vector3(4.5, cameraY, cameraZ);
    } else {
      camera.posterPos = new THREE.Vector3(4.5, cameraY + 0.5, cameraZ);
    }
    camera.targetPosition = camera.position;

    renderer = new THREE.WebGLRenderer({alpha: true, antialias: true})
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.shadowMap.enabled = true;
    wrapper = document.querySelector('#main-scene');
    wrapper.appendChild(renderer.domElement)
    window.addEventListener('resize', resize, false);

    // CSS RENDERER
    const containerCSS = document.getElementById( 'containerCSS' );
    rendererCSS = new CSS3DRenderer();
    rendererCSS.setSize( window.innerWidth, window.innerHeight );
    containerCSS.appendChild( rendererCSS.domElement );

    // RAYCASTER //
    raycaster = new THREE.Raycaster();
		pointer = new THREE.Vector2();

    // LIGHTS
    const ambLight = new THREE.AmbientLight(0xFFFFFF, 0.5);
    scene.add(ambLight);

    const dirLight = new THREE.DirectionalLight(0xFFFFFF, 0.3);
    dirLight.position.set(7.5, 3, 5);
    dirLight.target.position.set(7, 3, 0);
    dirLight.castShadow = true;
    dirLight.shadow.bias = -0.001;
    dirLight.shadow.camera.top = 6;
    dirLight.shadow.camera.bottom = -6;
    dirLight.shadow.camera.left = -12;
    dirLight.shadow.camera.right = 12;
    dirLight.shadow.camera.near = 0.1;
    dirLight.shadow.camera.far = 20;
    scene.add(dirLight);
    scene.add(dirLight.target);
    // const helper = new THREE.DirectionalLightHelper( dirLight, 5 );
    // scene.add( helper );
    // scene.add( new THREE.CameraHelper( dirLight.shadow.camera ) );

    // const controls = new OrbitControls( camera, renderer.domElement );

    const lobbyOverhead1 = new THREE.PointLight( 0xFFFFFF, 0.06, 100 );
    lobbyOverhead1.position.set( 5, 3, 4.5 );
    scene.add( lobbyOverhead1 );
    // const lobbyOverhead1Helper = new THREE.PointLightHelper( lobbyOverhead1, 1 );
    // scene.add( lobbyOverhead1Helper );



    // MODELS
    textureLoader = new THREE.TextureLoader();


    const easterEggZoomX = camera.posterPos.x;
    const easterEggZoomY = 3;
    const easterEggZoomZ = 2.5;

    modelLoader = new GLTFLoader();
    modelLoader.load('https://d39x71nefpqfvh.cloudfront.net/models/rhcp-wall_dream-canteen-2b.glb', gltf => {
      store = gltf.scene;

      store.traverse(node => {
        if ( node.isMesh ) { 
          node.castShadow = true;
          node.receiveShadow = true;
        }
      });

      recordPlaying = store.getObjectByName('record-now-playing');

      const albumStandDesktop = store.getObjectByName('album-stand-desktop');
      const albumStandMobile = store.getObjectByName('album-stand-mobile');

      if (window.Modernizr.touchevents) {
        albumStandDesktop.visible = false;
      } else {
        albumStandMobile.visible = false;
      }

      const recordStackCenter = store.getObjectByName('record-stack-center');
      if (window.innerWidth < 800) {
        recordStackCenter.position.y = recordStackCenter.position.y - 0.3;
      }

      const recordPlayerStand = store.getObjectByName('record-player-stand');
      if (window.innerWidth < 800) {
        recordPlayerStand.position.x = recordPlayerStand.position.x + 0.2;
      }

      
      penant = store.getObjectByName('penant_interactive');
      
      bass = store.getObjectByName('bass_interactive');
      if (window.innerWidth < 800) {
        bass.position.x = penant.position.x + 0.2
        bass.position.y = penant.position.y + 3.2
        bass.rotation.z = (-Math.PI / 2) + 0.09
      }

      bass.startPosition = new THREE.Vector3();
      bass.startPosition.copy(bass.position);
      bass.targetPosition = bass.position;
      bass.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ - 0.5);
      bass.startRotY = bass.rotation.y;
      bass.canRotate = true;
      easterEggs.push(bass)

      hat = store.getObjectByName('hat_interactive');
      hat.startPosition = new THREE.Vector3();
      hat.startPosition.copy(hat.position);
      hat.targetPosition = hat.position;
      hat.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ);
      hat.startRotY = hat.rotation.y;
      hat.canRotate = true;
      easterEggs.push(hat)

      setlist = store.getObjectByName('setlist_interactive');
      setlist.startPosition = new THREE.Vector3();
      setlist.startPosition.copy(setlist.position);
      setlist.targetPosition = setlist.position;
      if (window.innerWidth < 800) {
        setlist.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ + 1);
      } else {
        setlist.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY, easterEggZoomZ);
      }
      
      setlist.startRotY = setlist.rotation.y;
      setlist.canRotate = true;
      easterEggs.push(setlist)

      cassette = store.getObjectByName('cassette_interactive');

      cassette.startPosition = new THREE.Vector3();
      cassette.startPosition.copy(cassette.position);
      cassette.targetPosition = cassette.position;
      if (window.innerWidth < 800) {
        cassette.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY + 0.05, easterEggZoomZ + 1.9);
      } else {
        cassette.zoomPosition = new THREE.Vector3(easterEggZoomX, easterEggZoomY + 0.05, easterEggZoomZ + 0.5);
      }

      // cassette.rotation.x = 1
      // cassette.rotation.y = -1.5
      // console.log(cassette.quaternion)
      cassette.startQuaternion = new THREE.Quaternion();
      cassette.startQuaternion.copy(cassette.quaternion)
      
      cassette.targetQuaternion = cassette.quaternion;
      cassette.zoomQuaternion = new THREE.Quaternion(0.3507903300505317,  -0.5981942893050546, -0.32679502965768803, 0.6421173920529568);
      
      cassette.startRotY = cassette.rotation.y;
      cassette.canRotate = true;
      easterEggs.push(cassette)

      drum = store.getObjectByName('drum_interactive');
      // easterEggs.push(drum)

      tshirt = store.getObjectByName('tshirt_interactive');
      // easterEggs.push(tshirt)

      vinylPackage = store.getObjectByName('vinyl-package_interactive');
      // easterEggs.push(vinylPackage)

      

      vinylBanner = store.getObjectByName('vinyl-banner_interactive');
      vinylBanner.startPosition = new THREE.Vector3();
      vinylBanner.startPosition.copy(vinylBanner.position);
      vinylBanner.targetPosition = vinylBanner.position;
      vinylBanner.zoomPosition = new THREE.Vector3(0, 2.5, 2);
      vinylBanner.startRotY = vinylBanner.rotation.y;
      vinylBanner.canRotate = false;
      easterEggs.push(vinylBanner)

      icecreamBanner = store.getObjectByName('ice-cream-banner_interactive');
      icecreamBanner.startPosition = new THREE.Vector3();
      icecreamBanner.startPosition.copy(icecreamBanner.position);
      icecreamBanner.targetPosition = icecreamBanner.position;
      icecreamBanner.zoomPosition = new THREE.Vector3(0, 3.2, 2);
      icecreamBanner.startRotY = icecreamBanner.rotation.y;
      icecreamBanner.canRotate = false;
      easterEggs.push(icecreamBanner)

      if (icecreamBanner && window.innerWidth < 800) {
        icecreamBanner.zoomPosition = new THREE.Vector3(0, icecreamBanner.position.y, 2);
        icecreamBanner.position.y = icecreamBanner.position.y + 1.2;
        icecreamBanner.startPosition = new THREE.Vector3();
        icecreamBanner.startPosition.copy(icecreamBanner.position);
      }


      tvScreen = store.getObjectByName('tv-screen_interactive')
      const videoGeo = new THREE.BoxBufferGeometry( 0.8, 0.5, 0.01 );
      const tvVideoElement = document.getElementById( `video-1` );
      const vid1Texture = new THREE.VideoTexture( tvVideoElement );
      const vid1Material = new THREE.MeshLambertMaterial( {map:  vid1Texture});
      const tvVideoMesh = new THREE.Mesh(videoGeo, vid1Material);

      const tvScreenPosition = tvScreen.getWorldPosition(new THREE.Vector3())
      const tvScreenRotation = tvScreen.getWorldQuaternion(new THREE.Quaternion())
      tvVideoMesh.position.set(tvScreenPosition.x, tvScreenPosition.y, tvScreenPosition.z + 0.01)
      // tvVideoMesh.quaternion.copy(tvScreenRotation)
      tvVideoMesh.rotation.y = 0.36;
      tvVideoElement.play()
      scene.add(tvVideoMesh)

      store.traverse(node => {
        if (node.name.includes('interactive')) {
          objects.push(node)
        }
      })

      // // Explosion
      // const neonSignBase = store.getObjectByName('unlimited-love-neon-sign_base');
      // const explosionTexture = new THREE.TextureLoader().load( '/models/sprites/neon-gif.png' );
      // const explosionMaterial = new THREE.SpriteMaterial({map: explosionTexture});
      // explosionSprite = new THREE.Sprite( explosionMaterial );
      // explosionSprite.scale.set(2.87,2.87,2.87);
      // // tilesHoriz, tilesVert, numTiles, tileDispDuration
      // explosionAnimator = new TextureAnimator( explosionTexture, 27, 1, 27, 60 );
      // explosionSprite.visible = true;
      // explosionSprite.position.set(neonSignBase.position.x + 0.094, neonSignBase.position.y, neonSignBase.position.z + 0.01)
      // explosionAnimator.enabled = false;
      // scene.add(explosionSprite);


      scene.add(store)
      screenMesh.visible = true;

      // initMap()
      // resizeMap()

      console.log('3')
    })


    // THEATER CSS
    const obj = new THREE.Object3D

    const div = document.createElement( 'div' );
    const divMap = document.querySelector( '#map-container' );
    
    if (window.innerWidth < 800) {
      div.style.width = '600px';
      div.style.height = '1000px';
    } else {
      div.style.width = '600px';
      div.style.height = '600px';
    }
    div.style.backgroundColor = 'white';
    div.style.opacity = '1'
    div.id = 'map-screen';

    div.appendChild(divMap)

    cssScreen = new CSS3DObject( div );
    cssScreen.scale.set(0.005, 0.005, 0.005);
    cssScreen.rotation.y = 0;

    obj.add( cssScreen );

    // make an invisible plane for the DOM element to chop
    // clip a WebGL geometry with it.
    var material = new THREE.MeshPhongMaterial({
      opacity	: 0.15,
      blending: THREE.AdditiveBlending,
      color: 0x000000
      // side	: THREE.DoubleSide,
      // map: textureLoader.load('/models/textures/map-texture-overlay-5.png')
    });
    if (window.innerWidth < 800) {
      var geometry = new THREE.BoxGeometry( 3, 5, 0.001 );
    } else {
      var geometry = new THREE.BoxGeometry( 3, 3, 0.0001 );
    }
    screenMesh = new THREE.Mesh( geometry, material );
    screenMesh.castShadow = true;
    screenMesh.receiveShadow = true;
    screenMesh.visible = false;
    obj.lightShadowMesh = screenMesh
    obj.add( screenMesh );

    obj.position.set(0,2.7,0.55)
    scene.add(obj)
  }

  const initControls = () => {
    // wrapper.addEventListener( 'pointermove', onPointerMove );
    // wrapper.addEventListener( 'click', handleClick );

    const threeWrapper = document.querySelector('#three-wrap');
    threeWrapper.addEventListener( 'pointermove', onPointerMove );
    threeWrapper.addEventListener( 'click', handleClick );

    const objectCover = document.querySelector('#object-cover');
    objectCover.addEventListener( 'pointermove', objectPointerMove );
    objectCover.addEventListener( 'mousedown', objectPointerStart );
    objectCover.addEventListener( 'mouseup', objectPointerEnd );
    objectCover.addEventListener( 'touchstart', objectPointerStart );
    objectCover.addEventListener( 'touchend', objectPointerEnd );
  }

  const animate = () => {
    requestAnimationFrame(animate)

    camera.position.lerp(camera.targetPosition, 0.09);

    if (recordPlaying) {
      recordPlaying.rotation.y -= 0.04;
    }

    easterEggs.forEach(object => {
      object.position.lerp(object.targetPosition, 0.09);
      if (object.targetQuaternion && object.isZooming) {
        object.quaternion.slerp(object.targetQuaternion, 0.09)
      }
    })

    rendererCSS.render( scene, camera );
    renderer.render(scene,camera)    
  }

  const resize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight)
    rendererCSS.setSize(window.innerWidth, window.innerHeight);

    resizeMap();
  }

  const resizeMap = () => {
    const mapControls = document.querySelector('#map-controls')
    const mapScreen = document.querySelector('#map-screen')
    const mapContainer = document.querySelector('#map-container')
    
    const screenBox = mapScreen.getBoundingClientRect()
    const containerBox = mapContainer.getBoundingClientRect();

    mapControls.style.width = `${screenBox.width}px`;
    mapControls.style.height = `${screenBox.width}px`;

    const mapCanvas = mapContainer.querySelector('.mapboxgl-canvas-container');
    if (map.current && mapCanvas) {
      mapCanvas.style.width = `${containerBox.width}px`;
      mapCanvas.style.height = `${containerBox.height}px`;
      mapCanvas.style.position = `absolute`;
      mapCanvas.style.top = `50%`;
      mapCanvas.style.left = `50%`;
      mapCanvas.style.transform = `translate(-50%,-50%)`;
      mapCanvas.style.border = '2px solid red;';

      const searchContainer = document.querySelector('.mapboxgl-ctrl-geocoder');
      const mapLoading = document.querySelector('#map-loading');
      const mapSearchTrigger = document.querySelector('#map-search-trigger');
      if (window.innerHeight < 900) {
        cssScreen.scale.set(0.008,0.008, 0.008);
        searchContainer.classList.add('controls-resized');
        mapLoading.classList.add('map-loading-resized');
        mapSearchTrigger.classList.add('map-trigger-resized');
      } else {
        cssScreen.scale.set(0.005,0.005, 0.005);
        searchContainer.classList.remove('controls-resized');
        mapLoading.classList.remove('map-loading-resized');
        mapSearchTrigger.classList.remove('map-trigger-resized');
      }

      if (window.Modernizr.touchevents) {
        if (window.innerHeight < 900 && window.innerWidth < 800) {
          cssScreen.scale.set(0.01,0.01, 0.01);
          searchContainer.classList.add('controls-resized');
          mapLoading.classList.add('map-loading-resized');
          mapSearchTrigger.classList.add('map-trigger-resized');
        }
        if (window.innerWidth < 800 && window.innerHeight > 900) {
          // Mid size tablets
          cssScreen.scale.set(0.008,0.008, 0.008);
          searchContainer.classList.add('controls-resized');
          mapLoading.classList.add('map-loading-resized');
          mapSearchTrigger.classList.add('map-trigger-resized');
        }
        if (window.innerWidth < 380) {
          // Small phones
          cssScreen.scale.set(0.011,0.011, 0.011);
          searchContainer.classList.add('controls-resized');
          mapLoading.classList.add('map-loading-resized');
          mapSearchTrigger.classList.add('map-trigger-resized');
        }
      }

      map.current.resize()
    }
  }

  const setTargetLocation = (e) => {
    pointer.x = ( e.clientX / renderer.domElement.clientWidth ) * 2 - 1;
    pointer.y = - ( e.clientY / renderer.domElement.clientHeight ) * 2 + 1;

    raycaster.setFromCamera( pointer, camera );
    
    if (store) {
      const intersectsObjects = raycaster.intersectObjects(objects);
      
      if (intersectsObjects.length > 0) {
        pointer.target = intersectsObjects[ 0 ];
      } else {
        pointer.target = null;
      }
    }
  }

  const onPointerMove = (e) => {
    setTargetLocation(e)
  }

  const handleClick = (e) => {
    setTargetLocation(e)
    
    if (pointer && pointer.target && pointer.target.object) {
      if (pointer.target.object.name.includes('bass')) {
        bass.targetPosition = bass.zoomPosition;
        activeObject = bass;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('hat')) {
        hat.targetPosition = hat.zoomPosition;
        activeObject = hat;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('setlist')) {
        setlist.targetPosition = setlist.zoomPosition;
        activeObject = setlist;
        setObjectActive(true)
      } else if (pointer.target.object.name.includes('cassette')) {
        cassette.targetPosition = cassette.zoomPosition;

        cassette.isZooming = true
        setTimeout(() => {
          cassette.isZooming = false
        }, 1500);

        cassette.targetQuaternion = cassette.zoomQuaternion;
        activeObject = cassette;
        setObjectActive(true)
        props.togglePlaylistBtn()
      } else if (pointer.target.object.name.includes('drum')) {
        const drumLink = document.querySelector('#drum-link');
        drumLink.click();
      } else if (pointer.target.object.name.includes('tshirt')) {
        const tshirtLink = document.querySelector('#tshirt-link');
        tshirtLink.click();
      } else if (pointer.target.object.name.includes('vinyl-package')) {
        const vinylLink = document.querySelector('#vinyl-link');
        vinylLink.click();
      } else if (pointer.target.object.name.includes('penant')) {
        const penantlLink = document.querySelector('#penant-link');
        penantlLink.click();
      } else if (pointer.target.object.name.includes('vinyl-banner')) {
        vinylBanner.targetPosition = vinylBanner.zoomPosition;
        activeObject = vinylBanner;
        setObjectActive(true)

        const mapControls = document.querySelector('#map-controls');
        mapControls.dataset.posterleft = 'true';
      } else if (pointer.target.object.name.includes('ice-cream-banner')) {
        // icecreamBanner.targetPosition = icecreamBanner.zoomPosition;
        // activeObject = icecreamBanner;
        // // setObjectActive(true)
        // setIcecreamActive(true)

        
        // map.current.jumpTo({center: [-118.2330446,34.039007], zoom: 8})
        // props.getLocations(-118.2330446,34.039007);
        
        // loadingTimer = setTimeout(() => {
        //   const mapLoading = document.querySelector('#map-loading');
        //   mapLoading.dataset.status = 'active';
        // }, 2000);

        // const mapControls = document.querySelector('#map-controls');
        // mapControls.dataset.posterleft = 'true';
      } else if (pointer.target.object.name.includes('online-store-banner')) {
        const rhcpstorelink = document.querySelector('#online-store-link');
        rhcpstorelink.click();
      } else if (pointer.target.object.name.includes('tv')) {
        setVideoActive(true)
      } 
      
    }
  }

  const returnObject = () => {
    easterEggs.forEach(item => {
      item.targetPosition = item.startPosition;
      
      if (item.startQuaternion) {
        item.targetQuaternion = item.startQuaternion
        item.isZooming = true
        setTimeout(() => {
          item.isZooming = false
        }, 2000);
      }
    })
    console.log(activeObject)
    if (activeObject.name.includes('cassette')) {
      props.togglePlaylistBtn()
    }

    activeObject.rotation.y = activeObject.startRotY;
    activeObject = null;
    setObjectActive(false)
    setIcecreamActive(false)
    

    const mapControls = document.querySelector('#map-controls');
    mapControls.dataset.posterleft = 'false';
  }

  const closeVideo = () => {
    setVideoActive(false)
  }

  // Object rotation
  var mouseDown = false,
      mouseX = 0,
      mouseY = 0;
  const objectPointerMove = (e) => {
    if (!mouseDown) {
      return;
    }
    e.preventDefault();
    var deltaX = e.clientX - mouseX,
        deltaY = e.clientY - mouseY;
    mouseX = e.clientX;
    mouseY = e.clientY;
    rotateObject(deltaX, deltaY);
  }

  const objectPointerStart = (e) => {
    e.preventDefault();
    mouseDown = true;
    mouseX = e.clientX;
    mouseY = e.clientY;
  }
  const objectPointerEnd = (e) => {
    e.preventDefault();
    mouseDown = false;
  }

  function rotateObject(deltaX, deltaY) {
    if (activeObject && activeObject.canRotate) {
      if (!isNaN(deltaX) && !isNaN(deltaY)) {
        if (activeObject.name.includes('cassette')) {
          activeObject.rotation.y += deltaX / 100;
          activeObject.rotation.z += deltaX / 100;
        } else {
          activeObject.rotation.y += deltaX / 100;
        }
          
      }
    }
  }

  const openPinForm = () => {
    setFormActive(true)
  }

  const closePinForm = () => {
    setFormActive(false)
  }

  const viewAlbum = () => {
    setAlbumActive(true)
  }

  const closeAlbum = () => {
    setAlbumActive(false)
  }

  const navigate = (e) => {
    const direction = e.target.dataset.direction;
    if (direction === 'right') {
      setSceneView('posters');
      camera.targetPosition = camera.posterPos;
    } else {
      setSceneView('map');
      camera.targetPosition = camera.mapPos;
    }
  }

  const closeMapInstructions = () => {
    setMapInstructionsActive(false);
    // Trigger audio play if it hasn't started automatically
    document.dispatchEvent(new CustomEvent('patchPlayAudio'));
  }

  return(
    <div className={`${styles.wrapper} dreamCanteen`} data-scene={sceneView}>
      {/* <Map
        locations={props.locations}
        viewMarker={props.viewMarker}
      /> */}

      <div id='three-wrap' className={styles.threeWrap}>
        <div id='containerCSS' className={styles.cssCanvas}></div>
        <div id='main-scene' className={styles.webGLCanvas} data-pointer={sceneView === 'posters' ? 'true' : 'false'}></div>
      </div>

      <div ref={mapContainer} id='map-container' className={styles.mapContainer} data-debug={window.location.href.includes('localhost') || window.location.href.includes('heroku') ? true : false}>
        {/* <div ref={map} id='map' className={styles.map}></div> */}
        <div id='map-loading' className='map-loading' data-status='hidden'>Loading locations...</div>
        <div id='map-search-trigger' className='map-search-trigger'>Search this area</div>
      </div>

      <div id='map-controls' className={styles.controls}>
        <div onClick={viewAlbum} className={styles.albumTrigger}></div>

        <div onClick={openPinForm}  className={styles.pinTrigger}>
          <div className={styles.pinTriggerText}>
            DROP A PIN TO SHARE WHICH EDITION YOU PICKED UP & WHERE
          </div>
          <button className='btn btn--circle btn--circle--md btn--primary'>
            +
          </button>
        </div>
      </div>

      <button onClick={navigate} className={`${styles.navBtn} btn btn--circle btn--circle--sm btn--clear`} data-direction='left' data-active={sceneView === 'posters'}>
        <IconAngleLeft />
      </button>
      <button onClick={navigate} className={`${styles.navBtn} btn btn--circle btn--circle--sm btn--clear`} data-direction='right' data-active={sceneView === 'map'}>
        <IconAngleRight />
      </button>


      <div className={styles.objectOverlay} data-active={objectActive}>
        <div id='object-cover' className={styles.objectCover}></div>
        <div onClick={returnObject} className={styles.objectClose}>X CLOSE</div>
        {/* <a href={objectURL} target="_blank" rel="noreferrer" className={styles.objectBuy}>Buy Now</a> */}
      </div>

      {icecreamActive &&
        <div onClick={returnObject} className={styles.objectCloseIcecream}>X CLOSE</div>
      }

      
      
      <a id='penant-link' className={styles.onlineStoreLink} href='https://silverlakeconservatory.org/' target='_blank' rel='noreferrer'>.</a>
      <a id='drum-link' className={styles.onlineStoreLink} href='https://www.musicsupport.org/' target='_blank' rel='noreferrer'>.</a>
      <a id='tshirt-link' className={styles.onlineStoreLink} href='https://shop.redhotchilipeppers.com/' target='_blank' rel='noreferrer'>.</a>
      <a id='vinyl-link' className={styles.onlineStoreLink} href='https://music.redhotchilipeppers.com/products/return-of-the-dream-canteen-limited-white-blue-2lp' target='_blank' rel='noreferrer'>.</a>

      {albumActive
        ? <Album closeAlbum={closeAlbum} />
        : null
      }

      {formActive
        ? <PinForm closePinForm={closePinForm} locations={props.locations} />
        : null
      }

      {mapInstructionsActive
        ? <div className='info-window info-window--sm'>
          
            <p>
              Enter your location into the search bar to find record stores near you. Search by store, city, or zip.
            </p>
            <br/>
            <button className='btn btn--primary btn--block btn--lg' onClick={closeMapInstructions}>GOT IT</button>

            <div className='info-window__close' onClick={closeMapInstructions}>X CLOSE</div>
          </div>
        : null
      }


      <video id="video-1" className='video3D' crossOrigin="anonymous" playsInline muted loop>
        <source src="/models/video/static-2-2.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
      </video>

      {videoActive &&
        <VideoModal closeVideo={closeVideo} />
      }
    </div>
  )
}

export default Index;