import styles from './MenuPanel.module.scss';
import IconFacebook from '../../components/Icons/Facebook';
import IconTwitter from '../../components/Icons/Twitter';
import IconInstagram from '../../components/Icons/Instagram';

const menuPanel = (props) => (
  <div className={`${props.menuOpen ? styles.menuOpen : '' } ${styles.wrapper}`}>
    <div className={`${styles.content} dreamCanteen`}>
      <div className={styles.nav}>

        <ul>
          <li className={styles.label}><a href='https://redhotchilipeppers.com/tour/' target='_blank'>TOUR</a></li>
          <li className={styles.label}><a href='https://redhotchilipeppers.com/merch/' target='_blank'>MERCH</a></li>
        </ul>

        <div className={styles.socials}>
          <span className={styles.label}>FOLLOW</span>
          <div className={styles.iconList}>
            {/* <div className='subtitle'><a href='https://open.spotify.com/artist/0L8ExT028jH3ddEcZwqJJ5?si=XwaCwL6RSEeQ6YuCJEGt3Q&nd=1' target='_blank' rel='noreferrer'><i className='fab fa-spotify'></i></a></div>
            <div className='subtitle'><a href='https://music.apple.com/us/artist/red-hot-chili-peppers/889780' target='_blank' rel='noreferrer'><i className='fab fa-apple'></i></a></div> */}
            <div className='subtitle'><a href='https://www.instagram.com/chilipeppers/' target='_blank' rel='noreferrer'><IconInstagram /></a></div>
            <div className='subtitle'><a href='https://www.facebook.com/ChiliPeppers' target='_blank' rel='noreferrer'><IconFacebook /></a></div>
            <div className='subtitle'><a href='https://twitter.com/ChiliPeppers' target='_blank' rel='noreferrer'><IconTwitter /></a></div>
          </div>
        </div>
      </div>
      <div className={styles.album}>
        <div className={styles.albumArt}>
          <img src='https://d39x71nefpqfvh.cloudfront.net/images/album-art_dc.jpg' alt='album art' />
          <a className='btn btn--primary btn--block' href='https://rhcp.lnk.to/DreamCanteen' target='_blank' rel='noreferrer'>Listen/Purchase Now</a>
          
        </div>
      </div>
    </div>
  </div>
)

export default menuPanel;