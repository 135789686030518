import React, { useEffect, useState } from "react"
import styles from './Admin.module.scss';
// let map


function Admin() {
  const [passwordValid, setPasswordValid] = useState(false)
  const [password, setPassword] = useState()
  const [error, setError] = useState()
  const [pins, setPins] = useState();
  const [places, setPlaces] = useState();

  const [manualLocationAdd, setManualLocationAdd] = useState(false);
 
  useEffect(() => {
    if (password && passwordValid) {
      getPins();

      // const url = window.location;
      // const urlParams = new URLSearchParams(url.search);
      // const adminCreate = urlParams.get('addlocation');
      // console.log(adminCreate)
      // if (adminCreate) {
      //   setManualLocationAdd(true)
      // }
    }
  }, [password, passwordValid])

  const createLocations = async () => {
    // var sydney = new window.google.maps.LatLng(40.6976684,-74.2605412);

    // // infowindow = new google.maps.InfoWindow();

    // map = new window.google.maps.Map(
    //     document.getElementById('map'), {center: sydney, zoom: 4});

    // var request = {
    //   query: 'Mothers Ruin',
    //   fields: ['name', 'geometry'],
    //   locationBias: {radius: 50000, center: {lat: 40.6976684, lng: -74.2605412}}
    // };

    // var service = new window.google.maps.places.PlacesService(map);

    // service.textSearch(request, function(results, status, pagination) {
    //   console.log(pagination)
    //   if (status === window.google.maps.places.PlacesServiceStatus.OK) {
    //     console.log(results.length, results, status)
    //     setPlaces(results)
    //     for (var i = 0; i < results.length; i++) {
    //       // console.log('result', results[i])
    //     }
    //     // map.setCenter(results[0].geometry.location);
    //   }
    //   if (pagination && pagination.hasNextPage) {
    //     pagination.nextPage();
    //   }
    // });

    const rawAddresses = await fetch('/raw-addresses', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.log(error)
      return error
    })

    if (rawAddresses.error) {
      return false
    }

    for (const address of rawAddresses) {
      // const formatted_address = `${address.address} ${address.address_2} ${address.city}, ${address.state} ${address.zip}`;
      
      // const encodedAddress = encodeURIComponent(formatted_address)
      // const coords = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=AIzaSyBiliBVbFyGnCLXonvyhhCMImNgxIOWnh8`, {
      //   method: 'GET'
      // })
      // .then(response => response.json())
      // .then(data => {
      //   if (!data.results) {
      //     throw Error('Coords not found')
      //   }
        
      //   const info = data.results[0]
      //   if (!info || !info.geometry) {
      //     console.log(data)
      //     throw Error(`No geomtry for ${address.address}`)
      //   }
        
      //   const lat = info.geometry.location.lat;
      //   const lng = info.geometry.location.lng;

      //   return {lat,lng}
      // })
      // .catch(error => {
      //   console.log(`No data for ${address.address}`, error)
      //   return {error: {message: error}}
      // })

      // if (!coords.lat) {
      //   console.log(`No lat for ${address.address}`)
      //   return {error: {message: 'No lat'}}
      // }

      const body = {
        name: address.name,
        address: address.address,
        address_2: address.address_2,
        city: address.city,
        state: address.state,
        zip: address.zip,
        lat: address.lat,
        lng: address.lng,
        category: address.category
      }
      const saveRequest = await fetch('/locations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      .then(response => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
        console.log(error)
        return {error: {message: `Error creating: ${address}`}}
      })
    }
  }

  const getPins = async () => {
    console.log('getem')
    const request = await fetch('/pending-pins', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Password ${password}`
      }
    })
    .then(response => response.json())
    .then(data => {
      setPins(data)
      return data
    })
    .catch(error => {
      return error
    })
  }

  const approvePin = (e) => {
    fetch(`/pins/${e.target.dataset.pinid}`, {
      method: 'PUT',
      headers: {
       'Content-Type': 'applicaition/json',
       'Authorization': `Password ${password}`
      }
    })
    .then(response => response.json())
    .then(data => {
      getPins();
    })
    .catch(error => console.log(error))
  }

  const deletePin = (e) => {
    fetch(`/pins/${e.target.dataset.pinid}`, {
      method: 'DELETE',
      headers: {
       'Content-Type': 'applicaition/json',
       'Authorization': `Password ${password}`
      }
    })
    .then(response => response.json())
    .then(data => {
      getPins();
    })
    .catch(error => console.log(error))
  }

  const handlePasswordSubmit = (e) => {
    e.preventDefault();

    fetch('/admin-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({password})
    })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        setError('Invalid password')
        return
      }

      setPasswordValid(true);
    })
    .catch(error => console.log(error));
  }

  // const createLocation = () => {
  //   fetch('/locations', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //   .then(response => response.json())
  //   .then(data => console.log(data))
  //   .catch(error => console.log(error))
  // }

  return(
    <div className={styles.wrapper}>
      <div className={styles.content}>  
        {/* {manualLocationAdd
          ? <button onClick={createLocation}>add location</button>
          : null
        } */}


        {passwordValid && pins
          ? 
            <React.Fragment>
              <h1>Manage Fan Pins</h1>
              
              <div>
                <p>
                  Pins Pending Approval: {pins.length}
                </p>
                <table>
                  <thead>
                    <tr>
                      <th>DATE ADDED</th>
                      <th>ID</th>
                      <th>NAME</th>
                      <th>STORE</th>
                      <th>IMAGE</th>
                      <th>STATUS</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {pins.map((pin, i) => (
                      <tr key={`pin_${i}`}>
                        <td>{pin.createdAt}</td>
                        <td>{pin._id}</td>
                        <td>{pin.name}</td>
                        <td>{pin.location.name}</td>
                        <td>
                          <div className={styles.tableImgWrap}>
                          <img src={pin.image_url} />
                          </div>
                        </td>
                        <td>{!pin.approved ? 'Pending' : ''}</td>
                        <td>
                          <button onClick={approvePin} data-pinid={pin._id}>APPROVE</button>
                          <button onClick={deletePin} data-pinid={pin._id}>DELETE</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          : 
            <form onSubmit={handlePasswordSubmit}>
              <p>
                Enter your password:
              </p>
              <div>
                <input
                  type='text'
                  name='password'
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div>
                <p>{error}</p>
                <button type='submit'>SUBMIT</button>
              </div>
            </form>
        }
      </div>
    </div>
  )
}

export default Admin;