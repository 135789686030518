import React, { useEffect, useState } from 'react';
import EmailForm from './EmailForm';
import LoadingDc from '../../components/Screens/LoadingDc';
import styles from '../../components/Screens/ScreensDc.module.scss';

function WelcomeDc(props) {
  const [emailFormActive, setEmailFormActive] = useState()
  const [content, setContent] = useState('loading')

  useEffect(() => {
    if ( (props.audioReady || props.audioRedirect) && content == 'enter') {
      props.confirmWelcome();
    }
  }, [props.audioReady, props.audioRedirect])

  const showEmailForm = () => {
    setEmailFormActive(true)
  }

  const closeEmailForm = () => {
    setEmailFormActive(false)
  }

  const emailAdded = () => {
    setEmailFormActive(false)
    props.confirmWelcome();
  }

  const confirmLoading = () => {
    if (props.audioReady || props.audioRedirect) {
      props.confirmWelcome();
    } else {
      setContent('enter')
    }
  }
  
  return(
    <div className={`${styles.wrapper} dreamCanteen`}>
      {window.Modernizr.touchevents
        ? 
        <video className={styles.videoBg} poster="https://d39x71nefpqfvh.cloudfront.net/images/rhcp-dream-canteen_locator-bg-mobile-2.jpg" crossOrigin="anonymous" autoPlay playsInline muted loop>
          <source src="https://d39x71nefpqfvh.cloudfront.net/video/rhcp-dream-canteen_locator-bg-mobile.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
        </video>
        :
        <video className={styles.videoBg} poster="https://d39x71nefpqfvh.cloudfront.net/images/rhcp-dream-canteen_locator-bg-2.jpg" crossOrigin="anonymous" autoPlay playsInline muted loop>
          <source src="https://d39x71nefpqfvh.cloudfront.net/video/rhcp-dream-canteen_locator-bg.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
        </video>
      }
      

      <div className={styles.content}>
        <img src='https://d39x71nefpqfvh.cloudfront.net/icons/RHCP_LogoDC_500.png' className={styles.loadingLogo}/>
        {/* <h1 className={styles.welcomeHeader}>WELCOME TO THE</h1> */}

        <img src='https://d39x71nefpqfvh.cloudfront.net/images/rhcp-locator-title_mobile.png' className={styles.welcomeTitleImg} />
        

        <div className={styles.loadingConditional} data-status={content}>
          <LoadingDc loadingComplete={props.loadingComplete} confirmLoading={confirmLoading} />
        </div>

        <div className={styles.enterContent} data-status={content}>
          {props.audioReady
            ? <button onClick={props.confirmWelcome} className='btn btn--primary'>LET'S GO</button>
            : <div>
                <div class="patchbay-patch" data-id="63b8b7acbf5c295d3c2d3cfc">Loading...</div>
                <div className={styles.enterWithEmail}>
                  <button onClick={showEmailForm} className='btn btn--primary'>Or Enter  With Email</button>
                </div>
              </div>
          }
        </div>
      </div>

      {emailFormActive
        ? <EmailForm emailAdded={emailAdded} close={closeEmailForm} />
        : null
      }
    </div>
  )
}
  
export default WelcomeDc;