import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Cover from './Cover';
import App from './App';
import AppDc from './AppDc';
import Admin from './Admin';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    {/* {window.location.host.includes('redhotchilipeppers.com')
      ? <Cover />
      :
      <BrowserRouter>
        <Routes>
          <Route path={`/`} exact element={<App /> } />
          <Route path={`/admin`} exact element={<Admin />} />
        </Routes>
      </BrowserRouter>
    } */}

    <BrowserRouter>
      <Routes>
        {/* {(window.location.href.includes('heroku') || window.location.href.includes('localhost'))
          ? <Route path={`/`} exact element={<AppDc /> } />
          : <Route path={`/`} exact element={<App /> } />
        } */}
        <Route path={`/`} exact element={<AppDc /> } />
        <Route path={`/admin`} exact element={<Admin />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
