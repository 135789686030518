import styles from './DisclaimerDc.module.scss'

function DisclaimerDc(props) {
  return (
    <div className={`${styles.wrapper} dreamCanteen`}>
      <p>
        Our website uses cookies and similar technologies for analytics and to improve your user experience. By using this website, you consent to the use of cookies. Read our <a href='https://redhotchilipeppers.com/privacy-policy' target='_blank' rel='noreferr'>Privacy Policy and Terms of Use</a> to learn more.
      </p>
      <button onClick={props.acceptDisclaimer} className='btn btn--primary btn--block'>Accept Cookies</button>
    </div>
  )
}

export default DisclaimerDc;