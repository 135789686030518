import {useEffect, useState} from 'react'
import Nav from './containers/Nav/Index'
import Welcome from './containers/Screens/Welcome'
import Scene from './containers/Scene/Index'
import Location from './components/Scene/Location'
import Disclaimer from './containers/Screens/Disclaimer'

function App() {
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false)
  const [loadingComplete, setLoadingComplete] = useState(false)
  const [ui, setUI] = useState('welcome')
  const [audioRedirect, setAudioRedirect] = useState(false)
  const [audioReady, setAudioReady] = useState(false)

  const [locations, setLocations] = useState()
  const [locationsReady, setLocationsReady] = useState(false)
  const [pins, setPins] = useState()

  const [markerActive, setMarkerActive] = useState(false)
  const [activeLocation, setActiveLocation] = useState({})

  const [currentLocation, setCurrentLocation] = useState({lng: -118.2643453, lat: 34.0320466});
 
  useEffect( async () => {
    getUserLocation();
    setupAudio();
    // getLocations();
  }, [])

  useEffect(() => {
    const disclaimerCookie = document.cookie.replace(/(?:(?:^|.*;\s*)disclaimer_accepted\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    if (disclaimerCookie && disclaimerCookie === 'true') {
      setDisclaimerAccepted(true);
    }
  },[]);

  const acceptDisclaimer = () => {
    document.cookie = `disclaimer_accepted=true`;
    setDisclaimerAccepted(true);
  }

  const getUserLocation = async () => {
    if(!navigator.geolocation) {
      getLocations(currentLocation.lng, currentLocation.lat)
    } else {
      const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })
      .then(position => {
        setCurrentLocation({lat: position.coords.latitude, lng: position.coords.longitude})
        getLocations(position.coords.longitude, position.coords.latitude);
        return true
      })
      .catch(error => {
        getLocations(currentLocation.lng, currentLocation.lat)
        return false
      })
        
      return pos
    }
  }

  const updateCurrentLocation = (location) => {
    console.log(location)
    setCurrentLocation({lng: location.lng, lat: location.lat});
  }

  const setupAudio = () => {
    // Check for audio redirect immediately
    const url = window.location;
    const urlParams = new URLSearchParams(url.search);
    const accessToken = urlParams.get('access_token');
    const patchService = urlParams.get('service');
    
    if (accessToken && patchService) {
      setAudioRedirect(true)
    }

    const patchbayScript = document.createElement('script');
    patchbayScript.type = 'module';
    patchbayScript.onload = function () {
      //console.log('IM script')
    };
    patchbayScript.src = "https://js.patchbay.co/v1/embed.js?key=62327e2f39d1540691a469d2";
    document.head.appendChild(patchbayScript);

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(patchbayScript, firstScriptTag);

    document.addEventListener('audioOptOut', () => {
      confirmWelcome();
    });
    document.addEventListener('audioReady', () => {
      setAudioReady(true);
    });
    document.addEventListener('patchPlayerInit', () => {
      // Start the experience immediately. Don't wait for player loading to complete
      // or for audio to be fully ready.
      setAudioRedirect(true)
    });
  }

  const getLocations = async (lng,lat) => {
    setLocationsReady(true)
    
    const request = await fetch(`/locations-nearby?lat=${lat ? lat : currentLocation.lat}&lng=${lng ? lng : currentLocation.lng}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      setLocations(data)
      return data
    })
    .catch(error => {
      console.log(error)
      return error
    })

    setLocationsReady(true)

    if (lat && lng) {
      setCurrentLocation({lng: lng, lat: lat});
    }
  }

  const getPins = async () => {
    const request = await fetch('/pins', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      return error
    })

    console.log(request)
  }

  const confirmWelcome = () => {
    setUI('scene')
  }

  const viewMarker = (id) => {
    const location = locations.find(location => location._id === id)
    setActiveLocation(location)
    setMarkerActive(true)
  }

  const closeMarker = () => {
    setMarkerActive(false)
  }

  const updateModelLoading = () => {
    setLoadingComplete(true)
  }

  return (
    <div>
      <Scene  
        locationsReady={locationsReady}
        currentLocation={currentLocation}
        locations={locations}
        viewMarker={viewMarker}
        updateModelLoading={updateModelLoading}
        getLocations={getLocations}
        updateCurrentLocation={updateCurrentLocation}
      />

      {ui === 'welcome'
        ?
        <Welcome  
          loadingComplete={loadingComplete}
          audioReady={audioReady}
          audioRedirect={audioRedirect}
          confirmWelcome={confirmWelcome}
        />
        : null
      }

      <Nav ui={ui}/>

      <Location
        markerActive={markerActive}
        location={activeLocation}
        closeMarker={closeMarker}
      />

      <div class="patchbay-player" data-id="63b8b7acbf5c295d3c2d3cfc"></div>

      {disclaimerAccepted
        ? null
        : <Disclaimer acceptDisclaimer={acceptDisclaimer} />
      }
    </div>
  );
}

export default App;
