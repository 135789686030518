import React, {useState} from 'react';
import HeaderDc from '../../components/Nav/HeaderDc';
import MenuPanelDc from '../../components/Nav/MenuPanelDc';

function Nav(props) {
  const [menuOpen, setMenuOpen] = useState(false);

  const togglePanel = () => {
    if (menuOpen) {
      setMenuOpen(false)
    } else {
      setMenuOpen(true);
    }
  }

  return (
    <React.Fragment>
      <MenuPanelDc menuOpen={menuOpen} />
      <HeaderDc togglePanel={togglePanel} menuOpen={menuOpen} ui={props.ui} />
    </React.Fragment>
  )
}

export default Nav;