import { useState } from 'react';
import styles from './Sharing.module.scss';
import IconTwitter from '../../components/Icons/Twitter'
import IconFacebook from '../../components/Icons/Facebook'
import IconInstagram from '../../components/Icons/Instagram'

function Sharing(props) {
  const [sharingActive, setSharingActive] = useState(false);
  
  const showSharing = () => {
    setSharingActive(true)
  }
  const hideSharing = () => {
    setSharingActive(false)
  }

  const fbShare = (e) => {
		var url = e.target.dataset.url;
		document.dispatchEvent(new CustomEvent('fbShare', {detail: {url: url} }))
	}

  return (
    <div className={styles.sharingContent}>
      <button onClick={fbShare} className={styles.btnFacebook} data-url='https://usmap.redhotchilipeppers.com/'><IconFacebook /> share</button>
      <a href="https://twitter.com/intent/tweet?text=Explore the Red Hot Chili Peppers record store and find Return of the Dream Canteen near you!&url=https://usmap.redhotchilipeppers.com/&hashtags=ReturnOfTheDreamCanteen&via=ChiliPeppers" className={styles.btnTwitter}><IconTwitter /> share</a>
    
      <div className={`${styles.igShare} ${window.Modernizr.touchevents ? styles.igShareTouch : ''}`}>
        <button>
          <img src={`/images/sharing/ig-share.jpg`} />
          <div><IconInstagram /> hold to save</div>
        </button>

        <a className='' href={`/images/sharing/ig-share.jpg`} download="share-art.jpg"><IconInstagram /> download</a>
      </div>
    </div>
  )
}

export default Sharing;