import styles from './Nav.module.scss'

const header = (props) => (
  <div className={`${styles.wrapper} ${props.menuOpen ? styles.menuOpen : ''} `}>
    <div className={styles.title}>
      <img src={`https://d39x71nefpqfvh.cloudfront.net/icons/RHCP_LogoDC_500.png`} />
    </div>
    
    <div>
      <div onClick={props.togglePanel} className={styles.navTrigger}>
        <div className={styles.iconCenter}></div>
        <div className={styles.iconAnimate}></div>
      </div>
    </div>
  </div>
)

export default header;